@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&family=Permanent+Marker&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* background-color: black;
  color: white; */

  /* font-family: "Permanent Marker", cursive; */
}
